import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import classNames from 'classnames';

import './SearchPageBanner.scss';
import { ISearchBanner } from '@shared/types/umbraco/compositions/searchPageBlocks/SearchBanner';
import SearchInput from 'common/SearchInput';
import BreadCrumbs from '../../../common/BreadCrumbs';

const SearchPageBanner: FC<ISearchBanner> = ({
  properties: { title, inputPlaceholder, ariaClear, ariaSearch, ariaSearchForm },
  breadCrumbs = [],
  searchUrl = '/search/',
  gtmSearchSubmitEvent,
}) => (
  <div
    className={classNames('search-banner', 'search-banner--dark-theme')}
    data-testid="search-banner"
  >
    <Container fluid>
      <div className="search-banner__grid">
        <div className="search-banner__description">
          {breadCrumbs?.length ? <BreadCrumbs data={breadCrumbs} /> : null}
          {title ? (
            <DangerouslySetInnerHtml
              element="h1"
              className="search-banner__description-title"
              html={title}
            />
          ) : null}
          <SearchInput
            searchPlaceholder={inputPlaceholder}
            withAlgolia
            ariaClear={ariaClear}
            ariaSearchInput={ariaSearch}
            ariaSearchForm={ariaSearchForm}
            searchUrl={searchUrl}
            gtmSearchSubmitEvent={gtmSearchSubmitEvent}
          />
        </div>
      </div>
    </Container>
  </div>
);

export const query = graphql`
  fragment FragmentSearchBanner on TSearchBanner {
    properties {
      title
      inputPlaceholder
      ariaSearch
      ariaClear
      ariaSearchForm
    }
    structure
  }
`;

export default SearchPageBanner;
